<template >
  <div>
    <v-card class="mx-auto" max-width="1400">
      <v-img
        class="imgDiv portada white lighten-2"
        style="height: 600px"
        src="https://utaxon.com/img/home1.png"
        lazy-src="https://picsum.photos/id/11/100/60"
        aspect-ratio="1"
      >
        <div
          style="
            display: center !important;
            margin-top: 200px;
            font-family: 'Arial', Times, serif;
            font-weight: bold;
            font-size: 46px;
            color: white;
            text-align: center;
          "
        >
          Abre la puerta a la contabilidad inteligente
        </div>
      </v-img>
    </v-card>

    <v-dialog
      v-model="dialogoLogin"
      persistent
      max-width="450px"
      style="margin-top: -10px"
    >
      <v-card>
        <div
          id="loginRespuesta"
          style="
            margin-top: 20px;
            position: absolute;
            text-align: center;
            color: #042E52;
            font-family: 'Arial', Times, serif;
            font-size: 26px;
            width: 100%;
            font-weight: bold;
          "
        >
          Accede a tu cuenta
        </div>
        <v-card-text>
          <v-form
            style="max-width: 400px; margin-top: -10px"
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    style="margin-top: 48px; border-radius: 8px"
                    label="Correo electrónico"
                    v-model="correo"
                    ref="correoRef"
                    focusable
                    maxlength="60"
                    hint="correo@domain.com"
                    :rules="rulesCorreo"
                    filled
                    dense
                    hide-details="auto"
                    background-color="#f0f8ff"
                  ></v-text-field>

                  <v-text-field
                    style="margin-top: 8px; border-radius: 8px"
                    label="Contraseña"
                    :type="show1 ? 'text' : 'password'"
                    v-model="contrasenia"
                    maxlength="20"
                    hint="Ingrese su contraseña"
                    filled
                    dense
                    :rules="rulesContrasenia"
                    hide-details="auto"
                    background-color="#f0f8ff"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" style="text-align: right; width: 100%">
                  <!-- <router-link :to="{name: 'proceso'}" style="text-align: center;"> -->
                  <v-btn
                    rounded
                    class="mr-1"
                    color="#042E52"
                    style="
                      width: 110px;
                      font-size: 21px;
                      margin-top: 20px;
                      color: white;
                      text-transform: capitalize;
                      display: inline-block;
                    "
                    :disabled="!valid"
                    @click="loginx"
                    >Aceptar</v-btn
                  >
                  <!-- </router-link> -->
                </v-col>
                <v-col cols="6">
                  <!-- <router-link :to="{name: 'home'}" style="text-align: center;"> -->
                  <div style="text-align: left">
                    <v-btn
                      rounded
                      class="mr-1"
                      color="red"
                      @click="salir"
                      style="
                        width: 110px;
                        font-size: 21px;
                        margin-top: 20px;
                        color: white;
                        text-transform: capitalize;
                        display: inline-block;
                      "
                      >Cancelar</v-btn
                    >
                  </div>
                  <!-- </router-link> -->
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import router from "../router";
import { VueMaskDirective } from "v-mask";
import operations from "../operations";

export default {
  directives: {
    mask: VueMaskDirective,
  },
  name: "Login",

  data: () => ({
    show1: false,
    valid: true,
    lazy: false,
    dialogoLogin: true,
    correo: "", //'javier@transmex.com.mx',//'manuel.olan@billerticket.com',
    rulesCorreo: [
      (value) => !!value || "Requerido.",
      (value) => /.+@.+\..+/.test(value) || "El correo debe ser válido",
    ],
    contrasenia: "", //'gacf840629rv2',//'pruebas01' ,
    rulesContrasenia: [
      (value) => !!value || "Requerido.",
      (value) => (value && value.length >= 3) || "Min 8 caracteres",
    ],
  }),
  mounted() {
    //this.$refs.correoRef.focus()
  },
  created() {
    this.$store.state.sesion.email = null;
  },
  methods: {
    checkboxmethod() {
      this.terminos = !this.terminos;
    },
    terminosUrl() {
      window.open(
        "https://www.timeinc.net/subs/privacy/termsofservice/pptos.html",
        "_blank"
      );
    },
    async loginx() {
      console.log("loginx...");
      try {
        this.$store.state.cliente.password = this.contrasenia;
        this.$store.state.cliente.email = this.correo;
        console.log(this.contrasenia);
        console.log(this.correo);
        const response = await operations.login({
          correo: this.correo,
          contrasenia: this.contrasenia,
        });
        //console.log(response);
        if (response.status == "200") {
          console.log("ok");
          console.log(response);
          this.$store.state.sesion.email = this.correo;

          //router.push('/dashboard');//temp
          ///this.$store.state.billerticket=true;
          //return;

          try {
            //valida si ya se realizo el pago
            const pagoResponse = await operations.findPayment({
              username: this.correo,
            });
            console.log("respuesta findPayment");
            console.log(pagoResponse);
            console.log(pagoResponse.data);

            if (pagoResponse.data.payment == true) {
              //true
              this.$store.state.cliente.rfc =
                response.data.rfc == null ? "" : response.data.rfc;
              //ya tiene pago.
              try {
                //return;//temp
                //busca datos de biller ticket
                console.log(response.data.folio); //temp
                const valCteResponse = await operations.validaCliente({
                  folio: response.data.folio,
                }); //'AIRBNB0000000133'

                if (valCteResponse.status == "200") {
                  valCteResponse.data.rfc == null
                    ? (valCteResponse.data.rfc = "")
                    : valCteResponse.data.rfc;
                  if (valCteResponse.data.rfc != "") {
                    this.$store.state.billerticket = true;
                    this.$store.state.cliente.rfc = valCteResponse.data.rfc;
                    this.$store.state.dashboard.razonSocial =
                      valCteResponse.data.razonSocial;
                    //data available
                    //       rfc: "TOGJ010101EAA"
                    //       folioUtaxme: "AIRBNB0000000133"
                    //       clientId: "409"
                    //       razonSocial: "JORGE ANTONIO TORRES GARCIA"
                    //       userName: ""
                    //       celular: ""
                    //       codigo: "200"
                    //       mensaje: "Servicio Ejecutado Correctamente"
                    //       versionAndroid: 0
                    //       versionIos: 0
                    console.log("respuesta validaCliente");
                    console.log(valCteResponse);
                    console.log(valCteResponse.data);
                    router.push("/dashboard");
                    this.show1
                  } else {
                    //sus datos aun no estan actualizados en biller ticket
                    this.$store.state.billerticket = false;
                    this.$store.state.sesion.step = "proceso";
                    this.$store.state.cliente.celular =
                      response.data.telefono == null
                        ? "0000000000"
                        : response.data.telefono;
                    this.$store.state.cliente.nombre =
                      response.data.nombre == null
                        ? "Nombre"
                        : response.data.nombre;

                    router.push("/dashboard");
                  }
                }
              } catch (error) {
                console.log(error.data);
                this.$store.state.sesion.step = "proceso";
                this.$store.state.cliente.celular =
                  response.data.telefono == null
                    ? "0000000000"
                    : response.data.telefono;
                this.$store.state.cliente.nombre =
                  response.data.nombre == null
                    ? "Nombre"
                    : response.data.nombre;

                router.push("/proceso");
              }
            } else {
              //nuevo proceso. Flujo normal
              this.$store.state.sesion.step = "proceso";
              this.$store.state.cliente.celular =
                response.data.telefono == null
                  ? "0000000000"
                  : response.data.telefono;
              this.$store.state.cliente.nombre =
                response.data.nombre == null ? "Nombre" : response.data.nombre;

              router.push("/proceso");
            }
          } catch (error) {
            console.log("error");
            console.log(error);
            //nuevo proceso. Flujo normal
            this.$store.state.sesion.step = "proceso";
            this.$store.state.cliente.celular =
              response.data.telefono == null
                ? "0000000000"
                : response.data.telefono;
            this.$store.state.cliente.nombre =
              response.data.nombre == null ? "Nombre" : response.data.nombre;

            router.push("/proceso");
          }

          //   if(response.data.propuestaId != ''){
          //     this.$store.state.cliente.rfc= response.data.rfc == null ? '' : response.data.rfc;
          //     //ya tiene contrato. Flujo de un cliente con contrato existente
          //     router.push('/dashboard');
          //  }else{
          //     //nuevo proceso. Flujo normal
          //     this.$store.state.sesion.step="proceso";
          //     this.$store.state.cliente.celular= response.data.telefono == null ? '0000000000' : response.data.telefono;
          //     this.$store.state.cliente.nombre= response.data.nombre == null ? 'Nombre' : response.data.nombre;

          //     router.push('/proceso');
          //  }
        }
        if (response.status == "204") {
          console.log("204");
          document.getElementById("loginRespuesta").innerText =
            "Las credenciales ingresadas no existen";
          document.getElementById("loginRespuesta").style.fontSize = "20px";
        } 
      } catch (error) {
        console.log(error.response);
        console.log(error.response.data);

        if (error.response.status == 500) {
          document.getElementById("loginRespuesta").style.fontSize = "20px";
          if (error.response.data.response == "No coinciden las credenciales") {
            document.getElementById("loginRespuesta").innerText =
              "Credenciales inválidas";
          } else if (
            error.response.data.response.startsWith("could not extract")
          ) {
            document.getElementById("loginRespuesta").innerText =
              "El servicio esta temporalmente inactivo";
          } else {
            document.getElementById("loginRespuesta").innerText =
              "El usuario no esta activo en la plataforma";
          }
        } else {
          document.getElementById("loginRespuesta").innerText =
            error.response.data.response;
        }
      }
    },
    login() {
      let currentObj = this;
      document.getElementById("loginRespuesta").innerText =
        "Accede a tu cuenta";
      document.getElementById("loginRespuesta").style.fontSize = "26px";

      //debugger;
      this.axios
        .post("https://utaxon.com/utaxon/admin/find", {
          email: this.correo,
          password: this.contrasenia,
        })
        .then(function (response) {
          //debugger;

          console.log(response.status);
          console.log("Propuesta Id:" + response.data.propuestaId);
          if (response.status == "200") {
            if (response.data.propuestaId != "") {
              //ya tiene contrato. Flujo de un cliente con contrato existente
              router.push("/dashboard");
            } else {
              //nuevo proceso. Flujo normal
              router.push("/proceso");
            }
          } else {
            document.getElementById("loginRespuesta").innerText =
              "Las credenciales ingresadas no existen";
            document.getElementById("loginRespuesta").style.fontSize = "20px";
          }
        })
        .catch(function (error) {
          //debugger;
          document.getElementById("loginRespuesta").style.fontSize = "20px";
          console.log("error");
          console.log(error.response.data);

          if (error.response.data.code == "500") {
            if (
              error.response.data.response == "No coinciden las credenciales"
            ) {
              document.getElementById("loginRespuesta").innerText =
                "Credenciales inválidas";
            } else if (
              error.response.data.response.startsWith("could not extract")
            ) {
              document.getElementById("loginRespuesta").innerText =
                "El servicio esta temporalmente inactivo";
            } else {
              document.getElementById("loginRespuesta").innerText =
                "El usuario no esta activo en la plataforma";
            }
          } else {
            document.getElementById("loginRespuesta").innerText =
              error.response.data.response;
          }
        });
    },
    salir() {
      console.log("salir");
      //router.push('/');
      window.location = "https://www.utaxme.com";
    },
  },
};
</script>
<style scoped>
</style>