<template>
 <div>
    <content-login></content-login>
  </div>
</template>

<script>
  import ContentLogin from '../components/ContentLogin'

  export default {
    name: 'Login',

    components: {
      ContentLogin
    },
  }
</script>